@font-face {
  font-family: 'MarioFont';
  src:
    local('MarioFont'),
    url(../assets/fonts/SuperMario256.ttf) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.mario-font {
  font-family: 'MarioFont';
}

